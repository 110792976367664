// Colors
$primary-40-color: #ed722e; // primary color for all components
$primary-50-color: #ef8043; // primary color for all components
$primary-99-color: #fff8f4; // primary color for all components
$primary-color: #ed722e; // primary color for all components
$secondary-10-color: #016692; // primary color for all components
$secondary-30-color: #0199db; // primary color for all components
$secondary-40-color: #01aaf3; // primary color for all components
$secondary-50-color: #34bbf5; // primary color for all components
$secondary-60-color: #67ccf8; // primary color for all components
$secondary-95-color: #e6f7fe; // primary color for all components

$success-30-color: #389e0d; // primary color for all components
$success-60-color: #95de64; // primary color for all components
$success-90-color: #f0ffe1; // primary color for all components
$success-95-color: #f8fff1; // primary color for all components

$warning-40-color: #faad14; // primary color for all components
$warning-90-color: #fff8d6; // primary color for all components

$tertiary-20-color: #b3223e; // primary color for all components
$tertiary-60-color: #ff6f8b; // primary color for all components
$tertiary-95-color: #ffeaee; // primary color for all components
$tertiary-99-color: #fff8f9; // primary color for all components

$error-60-color: #ff5449; // primary color for all components

$geekblue-1-color: #f0f5ff; // primary color for all components

$goldenpurple-1-color: #f9f0ff; // primary color for all components

$neutral-10-color: #1a2e44;
$neutral-20-color: #1a2e44;
$neutral-40-color: #4d5d6d;
$neutral-50-color: #677482;
$neutral-60-color: #808b97;
$neutral-70-color: #99a2ac;
$neutral-80-color: #b3b9c1;
$neutral-95-color: #f1f2f3;
$neutral-99-color: #fbfbfb;
$neutral-100-color: #ffffff;

$warning-80-color: #FFF2BF;
$warning-30-color: #D99000;


$neutral-variant-0: #231b1c;
$neutral-variant-10: #3a2d2f;
$neutral-variant-20: #4e4244;
$neutral-variant-30: #615759;
$neutral-variant-40: #756c6d;
$neutral-variant-50: #898182;
$neutral-variant-60: #9d9697;
$neutral-variant-70: #b0abac;
$neutral-variant-80: #c4c0c1;
$neutral-variant-90: #d8d5d5;
$neutral-variant-95: #eaeaea; 
$neutral-variant-99: #f9f9f9;
$neutral-variant-100: #ffffff;

$link-color: #1890ff; // link color
$link-orange-color: #ed722e; // link color

$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color
$font-size-base: 14px; // major text font size
$font-size-sm: 12px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$text-color-tertiary: #010e1c; // settings link text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers



// crm tarafından alındı


// Font
$font-family: 'Roboto', sans-serif;

// Colors
$primary-0: #2f1709;
$primary-10: #5f2e12;
$primary-20: #a65020;
$primary-30: #d56729;
$primary-40: #ed722e;
$primary-50: #ef8043;
$primary-60: #f18e58;
$primary-70: #f29c6d;
$primary-80: #f4aa82;
$primary-90: #f8c7ab;
$primary-95: #fdf1ea;
$primary-99: #fff8f4;
$primary-100: #ffffff;
$primary-color: $primary-40; // primary color for all components

$secondary-0: #01557a;
$secondary-10: #016692;
$secondary-20: #0177aa;
$secondary-30: #0199db;
$secondary-40: #01aaf3;
$secondary-50: #34bbf5;
$secondary-60: #67ccf8;
$secondary-70: #80d5f9;
$secondary-80: #99ddfa;
$secondary-90: #b3e6fb;
$secondary-95: #e6f7fe;
$secondary-99: #f4fcff;
$secondary-100: #ffffff;
$secondary-color: $secondary-40;

$tertiary-0: #330a12;
$tertiary-10: #661424;
$tertiary-20: #b3223e;
$tertiary-30: #e62c50;
$tertiary-40: #ff3159;
$tertiary-50: #ff466a;
$tertiary-60: #ff6f8b;
$tertiary-70: #ff98ac;
$tertiary-80: #ffadbd;
$tertiary-90: #ffc1cd;
$tertiary-95: #ffeaee;
$tertiary-99: #fff8f9;
$tertiary-100: #ffffff;
$tertiary-color: $tertiary-40;

$error-0: #000000;
$error-10: #410001;
$error-20: #680003;
$error-30: #930006;
$error-40: #ba1b1b;
$error-50: #dd3730;
$error-60: #ff5449;
$error-70: #ff897a;
$error-80: #ffb4a9;
$error-90: #ffdad4;
$error-95: #ffede9;
$error-99: #fcfcfc;
$error-100: #ffffff;
$error-color: $error-40;
$error-color-hover: $error-60;
$error-color-active: $error-40;
$error-color-outline: $error-40;
$error-color-deprecated-bg: $error-90;
$error-color-deprecated-border: $error-80;

$warning-0: #613400;
$warning-10: #9f6a00;
$warning-20: #c78400;
$warning-30: #d99000;
$warning-40: #faad14;
$warning-50: #ffc53d;
$warning-60: #ffd666;
$warning-70: #ffe58f;
$warning-80: #fff2bf;
$warning-90: #fff8d6;
$warning-95: #fffced;
$warning-99: #fffdf4;
$warning-100: #ffffff;
$warning-color: $warning-40;
$warning-color-hover: $warning-60;
$warning-color-active: $warning-40;
$warning-color-outline: $warning-40;
$warning-color-deprecated-bg: $warning-90;
$warning-color-deprecated-border: $warning-80;

$success-0: #092b00;
$success-10: #1a5903;
$success-20: #237804;
$success-30: #389e0d;
$success-40: #52c41a;
$success-50: #73d13d;
$success-60: #95de64;
$success-70: #b7eb8f;
$success-80: #ddfdbe;
$success-90: #f0ffe1;
$success-95: #f8fff1;
$success-99: #fbfff6;
$success-100: #ffffff;
$success-color: $success-40;
$success-color-hover: $success-60;
$success-color-active: $success-40;
$success-color-outline: $success-40;
$success-color-deprecated-bg: $success-90;
$success-color-deprecated-border: $success-80;

$info-0: #01557a;
$info-10: #016692;
$info-20: #0177aa;
$info-30: #0199db;
$info-40: #01aaf3;
$info-50: #34bbf5;
$info-60: #67ccf8;
$info-70: #80d5f9;
$info-80: #99ddfa;
$info-90: #b3e6fb;
$info-95: #e6f7fe;
$info-99: #f4fcff;
$info-100: #ffffff;
$info-color: $info-40;
$info-color-hover: $info-60;
$info-color-active: $info-40;
$info-color-outline: $info-40;
$info-color-deprecated-bg: $info-90;
$info-color-deprecated-border: $info-80;

$link-color: #1890ff;

$neutral-0: #010e1c;
$neutral-10: #01172f;
$neutral-20: #1a2e44;
$neutral-30: #344559;
$neutral-40: #4d5d6d;
$neutral-50: #677482;
$neutral-60: #808b97;
$neutral-70: #99a2ac;
$neutral-80: #b3b9c1;
$neutral-90: #ccd1d5;
$neutral-95: #f1f2f3;
$neutral-99: #fbfbfb;
$neutral-100: #ffffff;

$neutral-variant-0: #231b1c;
$neutral-variant-10: #3a2d2f;
$neutral-variant-20: #4e4244;
$neutral-variant-30: #615759;
$neutral-variant-40: #756c6d;
$neutral-variant-50: #898182;
$neutral-variant-60: #9d9697;
$neutral-variant-70: #b0abac;
$neutral-variant-80: #c4c0c1;
$neutral-variant-90: #d8d5d5;
$neutral-variant-95: #ebeaea;
$neutral-variant-99: #f9f9f9;
$neutral-variant-100: #ffffff;
