@import '/src/styles/ant.variables.theme.scss';
.label_color {
  color: $neutral-60-color;
}
.upload-container {
  width: 100%; /* Set a specific width for the container */
  height: 300px; /* Set a specific height for the container */
  border: 1px solid #ccc; /* Add borders for visualization */
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

/* Adjust the style of the Ant Design Upload component if necessary */
.upload-container .ant-upload {
  width: 100%; /* Make sure the Upload component takes 100% width of its parent */
}

.hide {
  display: none;
}
