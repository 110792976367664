@import '/src/styles/ant.variables.theme.scss';

.sidebar {
  position: relative;
  // background-color: #f3426d;
  width: 282px;
  padding-top: 24px;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  border-right: 1px solid $neutral-variant-95;
  &Comp {
    border-inline-end: none !important;
  }
  &Inner {
    position: sticky;
    top: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }
  .sidebarMenuBtn {
    display: none;
    position: absolute;
    top: 32px;
    right: -12px;
    z-index: 2;
    font-size: 0;
    line-height: 1;
    color: $neutral-40;
    svg {
      margin-left: -2px;
    }
    &2 {
      margin-left: 4px;
    }
  }
  &Menu {
    &Btn {
      margin: 0 auto 0 4px;
    }
  }
  &Collapsed {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 16px 0 0;
    &Btn {
      background: none;
      padding: none;
      margin: 0 auto;
      border: none;
      cursor: pointer;
      &:hover {
        color: $primary-40;
      }
      &.active {
        padding: 0px 12px 10px 0;
      }
    }
  }
  &:hover {
    .sidebarMenuBtn {
      display: block;
    }
  }
  :global {
    .ant-menu-inline-collapsed {
      width: 47px;
    }
  }
  &.sidebarCollapsedWidth {
    width: 48px;
    & + main {
      width: calc(100% - 48px);
    }
  }
}
