// @import '~antd/lib/style/themes/default.less';
// @import '~antd/dist/antd.less';

// Font

@import '~antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap&.css');
$font-family: 'Roboto', sans-serif;

@import '/src/styles/ant.variables.theme.scss';

html {
  scroll-behavior: smooth;
}

// *,
// *:after,
// *:before {
//   box-sizing: border-box;
// }
@media only screen and (max-width: 480px) {
  /* Küçük mobil cihazlar için stiller */
  .container {
    width: 100%;
    padding: 0 10px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* Orta boy mobil cihazlar için stiller */
  .container {
    width: 100%;
    /* Stiller */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  body {
    margin: 0;
    // font-family: var(--font-family);
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    // min-width: 640px;
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1200px) {
  body {
    margin: 0;
    // font-family: var(--font-family);
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-width: 1200px;
    overflow-x: auto;
  }
}

.governor {
  display: flex;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
  .safe {
    flex-grow: 1;
    max-width: 1672px;
  }
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
}
