.title {
  color: #01172f;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
