@import '/src/styles/ant.variables.theme.scss';

.header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 32px;
  min-width: 1200px;
  height: 64px;
  background: #fbfbfb;
  //background-color: '#f3426d';
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02),
    0px 2px 4px rgba(0, 0, 0, 0.02);
  &.visibleProjectSelector {
    height: 72px;
  }
  &Left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    &Company {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px 9px;
      gap: 10px;
      max-width: 119px;
      height: 28px;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      transition: all 0.3s ease-in-out;
      color: rgba(0, 0, 0, 0.88);
      cursor: default;
      span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        line-height: 20px;
      }
      &:hover {
        max-width: max-content;
        background-color: $neutral-30;
        color: #fff;
      }
    }
  }
  &Right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
}

.projectSelector {
  display: flex;
  justify-content: center;
  gap: 48px;
  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;
    height: 72px;
    text-decoration: none;
    gap: 2px;
    div {
      color: rgba(0, 0, 0, 0.88);
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
    &:after {
      content: '';
      width: 100%;
      height: 0px;
      background-color: $primary-40;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      &:after {
        height: 2px;
      }
    }
    &Active {
      div {
        color: $primary-40;
      }
      &:after {
        height: 2px;
      }
    }
  }
}
