@import '/src/styles/ant.variables.theme.scss';

.account__notification__dropdown {
  width: 467px;
  background: $neutral-variant-100;
  border-radius: 4px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  &_head {
    padding: 10px 16px;
    border-bottom: 1px solid $neutral-95;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &_title {
      font-size: 16px;
      color: $neutral-40;
    }
  }
  &_body {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
  &_foot {
    border-top: 1px solid $neutral-95;
  }
}

.isRead {
  background-color: #fafafa;
}
.isNotRead {
  background-color: white;
}
