@import '/src/styles/ant.variables.theme.scss';

.headLine {
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  color: $neutral-10;
  margin-bottom: 0;
  &1 {
    font-size: 57px;
    line-height: 64px;
  }
  &2 {
    font-size: 45px;
    line-height: 52px;
  }
  &3 {
    font-size: 36px;
    line-height: 44px;
  }
  &4 {
    font-size: 32px;
    line-height: 50px;
  }
  &5 {
    font-size: 24px;
    line-height: 32px;
  }
  &6 {
    font-size: 23px;
    line-height: 28px;
  }
  &Strong {
    font-weight: 600;
  }
}
