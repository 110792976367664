.page_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  // overflow: auto;
  max-width: 100%;
  height: 100%;
}

.footer {
  background-color: #ffffff;
}


/*
Spin background rengini beyaz yapma
*/
.page_container + .ant-spin-nested-loading .ant-spin-blur {
  clear: both;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}