@import '/src/styles/ant.variables.theme.scss';

.account__profile__dropdown {
  width: 100%;
  width: 184px;
  background: $neutral-variant-100;
  border-radius: 4px;
  padding: 4px 0px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  &_divider {
    border-bottom: 1px solid $neutral-95;
  }
  &_head {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &_avatar {
      border: 1px solid $neutral-90;
      border-radius: 4px;
    }
    &_title {
      text-align: center;
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      color: $neutral-60;
      margin: 8px 15px;
    }
    &_manage {
      width: 145px;
      height: 40px;
      border: 1px solid #ccd1d5;
      border-radius: 4px;
      font-weight: 450;
    }
    &_register {
      width: 145px;
      height: 40px;
      border: 1px solid #ccd1d5;
      border-radius: 4px;
      font-weight: 380;
    }
  }
  &_menu {
    width: 100%;
    &_link {
      width: 100%;
      text-align: left;
      width: 145px;
      font-weight: 470;
    }
    &_signout {
      width: 100%;
      height: 40px;
      text-align: left;
      font-weight: 470;
    }
    > div {
      width: 100%;
    }
  }
}

.login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  width: 275px;
  // height: 572px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  &__first {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    &_avatar {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
    }
    &_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      width: 243px;
      height: 38px;
      cursor: pointer;
      &_name {
        width: 243px;
        height: 22px;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: #010e1c;
      }
      &_company {
        width: 243px;
        height: 16px;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        color: #99a2ac;
      }
    }
  }
  &__second {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 8px;
    width: 100%;
    max-height: 148px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    &::-webkit-scrollbar {
      width: 3px !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px !important;
      background-color: $neutral-70 !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
    &_text {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 16px 0px;
      gap: 10px;
      width: 275px;
      height: 24px;
      font-size: 11px;
      line-height: 16px;
      color: #808b97;
    }
    &_up {
      display: flex;
      align-items: center;
      padding: 12px;
      gap: 8px;
      width: 275px;
      height: 56px;
      cursor: pointer;
      &_avatar {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        background: #ff6f8b;
      }
      &_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 211px;
        height: 32px;
        &_name {
          width: 211px;
          height: 16px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #010e1c;
        }
        &_company {
          width: 211px;
          height: 16px;
          font-size: 11px;
          line-height: 16px;
          color: #99a2ac;
        }
      }
    }
  }
  &__third {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px 0px;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    &_menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0;
      &_item {
        display: flex;
        align-items: center;
        padding: 7px 16px;
        width: 100%;
        font-size: 12px;
        line-height: 18px;
        color: #010e1c;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #eaeaea;
        }
      }
    }
  }
  &__fourth {
    &:hover {
      background-color: rgb(245, 245, 245);
    }
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    padding: 12px 16px;
    gap: 8px;
    width: 100%;
    height: 42px;
    color: #010e1c;
    cursor: pointer;
  }
}
