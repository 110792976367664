.btn {
  cursor: pointer;
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.88);
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  height: 22px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  user-select: none;
  outline: none;
  span {
    ~ span {
      margin-inline-start: 8px;
    }
  }
  &.sizeSmall {
    font-size: 12px;
    line-height: 20px;
    height: 20px;
  }
  &.sizeLarge {
    font-size: 16px;
    line-height: 24px;
    height: 24px;
  }
  &:hover {
    color: #fa9657;
  }
  &:active {
    color: #c7521c;
  }
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
