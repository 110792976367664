@import '/src/styles/ant.variables.theme.scss';
  
  .new_order_label
    // .preparing_order_label
    // .prepared_order_label
    // .shipped_from_seller_order_label
    // .departure_warehouse_order_label
    // .on_the_voyage_order_label
    // .destination_warehouse_order_label
    // .shipped_to_buyer_order_label
  {
    inline-size: fit-content;
    color: $neutral-50-color;
    background: $neutral-99-color;
    border-radius: 4px;
    padding: 3px 8px;
    border: 1px solid #eeeeee;
  }
  .completed_order_label {
    inline-size: fit-content;
    color: $success-30-color;
    background: #ddfdbe;
    border-radius: 4px;
    padding: 3px 8px;
    border: 1px solid #eeeeee;
  }
  .in_distribution_order_label {
    inline-size: fit-content;
    color: $neutral-50-color;
    background: $neutral-99-color;
    border-radius: 4px;
    padding: 3px 8px;
    border: 1px solid #eeeeee;
  }
  
  
  //buttons types
  //Discard Button Class -->>> discard button should be  type='text'
  .discard_button {
    margin-left: -16px !important;
  }
  
  //  save and discard Buttons should be child of element with classname .control_container like:
  // <div className={styles.control_container}>
  // <Button className={styles.discard_button} type='text'size='large'></Button>
  // <Button type='primary' size='large'></Button></div>
  //
  .control_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;}
  
  
  .waiting_label {
    inline-size: fit-content;
    color: $neutral-50-color;
    background: $neutral-99-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .preview_label {
    inline-size: fit-content;
    color: $secondary-10-color;
    background: $geekblue-1-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .approved_label {
    inline-size: fit-content;
    color: $success-30-color;
    background: $success-90-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .rejected_label {
    inline-size: fit-content;
    color: $error-60-color;
    background: $tertiary-99-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .wrong_label {
    inline-size: fit-content;
    color: $tertiary-60-color;
    background: $tertiary-95-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .payable_label {
    inline-size: fit-content;
    color: $primary-50-color;
    background: $primary-99-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .completed_label {
    inline-size: fit-content;
    color: $tertiary-20-color;
    background: $goldenpurple-1-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  .new_label {
    inline-size: fit-content;
    color: $secondary-50-color;
    background: $secondary-95-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .preparing_label {
    inline-size: fit-content;
    color: $warning-40-color;
    background: $warning-90-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .released_label {
    inline-size: fit-content;
    color: $success-60-color;
    background: $success-95-color;
    border-radius: 4px;
    padding: 3px 8px;
  
  }

  .active_label {
    inline-size: fit-content;
    color: $secondary-50-color;
    background: $secondary-95-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  .passive_label {
    inline-size: fit-content;
    color: $neutral-50-color;
    background: $neutral-99-color;
    border-radius: 4px;
    padding: 3px 8px;
  }
  
  
  
.label_r_small {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: $neutral-20-color;
}

.label_r_medium {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $neutral-20-color;
}

.label_r_large {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $neutral-20-color;
}

.body_r_small {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $neutral-40-color;

}

.body_r_medium {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $neutral-40-color;
}

.body_r_large {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $neutral-40-color;
}

.title_r_small {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $neutral-10-color;
}

.title_r_medium {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $neutral-10-color;
}

.title_r_large{
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: $neutral-10-color;
}

.headline_r_small {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $neutral-10-color;
}

.headline_r_medium {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: $neutral-10-color;
}

.headline_r_large {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: $neutral-10-color;
}

.display_r_small {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  color: $neutral-10-color;
}

.display_r_medium {
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 62px;
  color: $neutral-10-color;
}

.display_r_large{
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 74px;
  color: $neutral-10-color;
}

.label_m_small {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px; 
  color: $neutral-20-color;
}

.label_m_medium {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $neutral-20-color;
}
.label_m_large{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $neutral-20-color;
}

.body_m_small {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $neutral-40-color;
}

.body_m_medium {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $neutral-40-color;
}

.body_m_large {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $neutral-40-color;
}

.title_m_small {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $neutral-10-color;
}

.title_m_medium {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $neutral-10-color;
}

.title_m_large {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: $neutral-10-color;
}

.headline_m_small{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $neutral-10-color;
}
.headline_m_medium{
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: $neutral-10-color;
}
.headline_m_large{
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: $neutral-10-color;
}

.display_m_small{
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: $neutral-10-color;
}
.display_m_small{
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 52px;
  color: $neutral-10-color;
}
.display_m_small{
  font-style: normal;
  font-weight: 600;
  font-size: 57px;
  line-height: 64px;
  color: $neutral-10-color;
}