.search {
  margin-bottom: 16px;
}
.table_container {
  max-width: 100%;
}
// .ant-table {
//   :global {
//     .ant-table-body {
//       overflow-y: auto !important;
//     }
//     .ant-table-container {
//       box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
//     }
//     .ant-table th {
//       background-color: white;
//     }
//   }
// }

.control_container {
  display: flex;
  justify-content: right;
}

.button {
  //margin: 3px 3px 3px 3px;

  width: 40px !important;
  height: 40px !important;
  border: none;
  background-color: transparent;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
}
.button:hover {
  background-color: transparent;
}

.action_colum {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  // padding: 12px 16px;
  gap: 16px;

  :global {
    .ant-btn[disabled] {
      border: none;
      background: transparent;
    }
  }
}

.summery_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.summery_value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.table_icon {
  font-size: 12px;
}
