@import '/src/styles/ant.variables.theme.scss';

.menuDropdown {
  width: 160px;
  background: $neutral-variant-100;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  &StepSelections {
    display: block;
  }
  &Menu {
    width: 100%;
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        button {
          width: 100%;
          height: 40px;
          padding: 10px 18px 10px 12px;
          border-radius: 4px;
          border: none;
          background: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .menuDropdownMenuText {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            width: calc(100% - 20px);
            text-align: left;
          }
          .menuDropdownMenuIcon {
            opacity: 0;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.018);
          }
          &.menuDropdownMenuChecked {
            .menuDropdownMenuIcon {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &Footer {
    padding: 8px;
  }
}
