@import '/src/styles/ant.variables.theme.scss';

.layout {
  background-color: $neutral-100;
  &__body {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 136px);
    justify-content: space-between;
    align-items: stretch;
  }
  &__has_sidebar {
    .layout__container {
      width: calc(100% - 280px);
      min-height: calc(100vh - 136px);
      padding: 0 32px 32px 32px;
      transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    &_actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

