@import '/src/styles/ant.variables.theme.scss';

.footer {
  min-width: 1200px;
  height: 64px;
  display: grid;
  background-color: $neutral-100;
  z-index: 10;
  position: relative;
  padding: 0 32px;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
  .logo {
    width: 148px;
    height: 56px;
  }
  &__menu {
    height: 100%;
  }
  &__copyright {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    span {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: $neutral-0;
    }
  }
}
