@import '/src/styles/ant.variables.theme.scss';

.layout {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: $neutral-variant-100;
}

.header {
  width: 100%;
  height: 64px;
  background-color: $neutral-variant-100;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.scrollUp {
    top: 0;
  }
  &.scrollDown {
    top: -65px;
  }
  &.scrolling {
    position: sticky;
  }
}

.page {
  width: 100%;
  min-height: calc(100vh - 136px);
  display: flex;
  flex-direction: column;
  &Header {
    width: 100%;
    height: 64px;
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    &Title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $neutral-10;
      letter-spacing: 0.0015em;
    }
  }

  &Body {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 772px;
    padding: 8px 32px 72px 32px;
    margin: 0 auto;
  }
  &BodyFull {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 8px 32px 72px 32px;
    margin: 0 auto;
  }
  &Loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &Centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &Steps {
    width: 100%;
    padding: 0 32px;
  }
}

.footer {
  width: 100%;
  height: 72px;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 32px;
  gap: 32px;
}
